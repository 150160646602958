// @flow
import * as React from 'react'

import { buttonStyle } from '../utils/style'

export default function NotFoundPage() {
  return (
    <div className="container">
      <picture>
        <source srcSet="/404-hero.jpg 1x, /404-hero@2x.jpg 2x" />
        <img
          className="hero-image"
          width="574"
          height="293"
          src="/404-hero.jpg"
          alt="Page not found"
        />
      </picture>
      <h1>Страница не найдена</h1>

      <a href="https://shipper.space/parcels" className="button">
        Вернуться к посылкам
      </a>

      <style jsx>{buttonStyle}</style>
      <style jsx>{`
        .container {
          padding-top: 180px;

          text-align: center;
        }

        .hero-image {
          display: block;
          width: 100%;
          max-width: 574px;
          height: auto;
          margin: 0 auto;
        }

        h1 {
          margin: 48px 0 56px 0;

          font-size: 24px;
          line-height: 1.2;

          color: #14145a;
        }

        .button {
          display: inline-block;
          padding: 9px 21px;
          width: auto;
          margin: 0 auto;
        }
      `}</style>
    </div>
  )
}
